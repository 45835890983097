@font-face {
  font-family: "iranYekan";
  src: url("../public/font/YEKAN\ BAKH\ EN\ 04\ REGULAR.TTF");
}

body {
  background-color: white;
  overflow-x: hidden;
  font-family: "iranYekan";
  overflow-x: hidden;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.border-sec {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}
.border-sec::before {
  content: "";
  position: absolute;
  background-color: #c2a200;
  width: 38%;
  height: 2px;
  bottom: 35%;
  right: 3%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.282), 0 1px 2px -1px;
}
.border-sec::after {
  content: "";
  position: absolute;
  background-color: #c2a200;
  width: 38%;
  height: 2px;
  bottom: 35%;
  left: 3%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.282), 0 1px 2px -1px;
}
.choose:hover span {
  bottom: 0%;
}
.paginationbttn {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-size: 13px;
}
.paginationbttn a {
  padding: 8px;
  padding-left: 13px;
  padding-right: 13px;
  margin: 8px;
  background-color: #fff;
}
.paginationbttn a:hover {
  background-color: #eee;
  transition-duration: 0.3s;
}
.activebttn a {
  background-color: #000000;
  color: white;
  transition-duration: 0.3s;
}
.cate:hover div {
  height: fit-content;
  opacity: 1;
  width: 450px;
  top: 70px;
  right: 60px;
}
#protal-magnify div {
  transform: none !important;
  inset: 8vh 204px 0 53vw !important; 
}
.magniimg {
  max-width: none !important;
  max-height: none !important;
}
.sale{
  position: relative;
}
.sale::after{
  content: "";
    position: absolute;
    background-color: #c20000;
    width: 95%;
    height: 1px;
    bottom: 50%;
    rotate: 8deg;
    right: 0%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.282), 0 1px 2px -1px;
}

@media screen and (max-width: 600px) {
  .paginationbttn a {
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    background-color: #fff;
  }
  body{
    padding-top: 70px;
  }
  .activebttn a {
    background-color: #000000;
    color: white;
    transition-duration: 0.3s;
  }
  .paginationbttn a:hover {
    background-color: #000000;
    transition-duration: 0.3s;
  }
  .border-sec::before {
    content: "";
    position: absolute;
    background-color: #c2a200;
    width: 20%;
    height: 2px;
    bottom: 35%;
    right: 3%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.282), 0 1px 2px -1px;
  }
  .border-sec::after {
    content: "";
    position: absolute;
    background-color: #c2a200;
    width: 20%;
    height: 2px;
    bottom: 35%;
    left: 3%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.282), 0 1px 2px -1px;
  }
}
